<template>
  <div class="recycling-waste" style="background-color: #f8fcff;">
       <!-- <div class="banner">
    </div> -->
		<div class="banner">
      <el-carousel class="banner-carousel" indicator-position="outside">
        <el-carousel-item
          v-for="item in dashboardData.photos"
          :title="item.value3"
          :key="item.id"
        >
          <img
            :title="item.value3"
            :src="item.path"
            @click="linkToResolve(item.value4)"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-card :body-style="{padding:'0' } " style="width:70.5%;margin: 20px auto;" class="box-card">
			<div class="car-header">
				<p class="p1">新车交易</p>
				<p class="p2"></p>
				<p class="p3">
					公司长期从事品牌新车销售，主营品牌有东风本田、东风日产、上汽大众、通用别克、一汽大众等品牌数十种车型。
					合理的价格是开始，终身的服务是保障，期待为您服务。
				</p>
				<div class="waste-hot">
					<span class="phone">咨询热线：</span>
					<span class="phone">18829052629</span>
				</div>
    	</div>
      <div class="lastBox-right">
        <el-row :gutter="20" class="recycling-waste-list" v-if="value === '1'">
					<el-col :span="8" class="recycling-waste-item" v-for="(item, index) in newCarImageList" :key="index">
						<img :src="item.src" alt=""/>
						<span class="recycling-waste-text">{{item.text}}</span>
					</el-col>
				</el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "adressMaintain",
  data() {
    return {
			value: '1',
			newCarImageList: [
				{
					src: '/img/car/new-car1.png',
					text: '本田CRV   四驱豪华版'
				},
				{
					src: '/img/car/new-car2.png',
					text: '本田CRV都市版'
				},
				{
					src: '/img/car/new-car3.png',
					text: '本田CRV风尚版'
				},
				{
					src: '/img/car/new-car4.png',
					text: '本田CRV两驱黑爵士版'
				},
				{
					src: '/img/car/new-car5.png',
					text: '本田CRV两驱舒适版'
				},
				{
					src: '/img/car/new-car6.png',
					text: '本田XRV CVT 热力版'
				},
				{
					src: '/img/car/new-car7.png',
					text: '本田思域劲动版'
				},
				{
					src: '/img/car/new-car8.png',
					text: '本田思域燃动版'
				},
				{
					src: '/img/car/new-car9.png',
					text: '别克英朗21款典范自动精英'
				},
				{
					src: '/img/car/new-car10.png',
					text: '大众朗逸1.5L自动舒适'
				},
				{
					src: '/img/car/new-car11.png',
					text: '大众朗逸280自动舒适'
				},
				{
					src: '/img/car/new-car12.png',
					text: '东风日产天籁'
				},
				{
					src: '/img/car/new-car13.png',
					text: '东风日产逍客'
				},
				{
					src: '/img/car/new-car14.png',
					text: '东风日产轩逸'
				},
			]
    };
  },
  computed: {
    ...mapState({
      dashboardData: (state) => {
        return state.home.dashboardData;
      },
      loading: (state) => {
        return state.loading;
      },
    }),
  },
  created() {
    this.value = this.$route.query.value;
    this.active = this.$route.query.value;
    if (this.value == undefined) {
      this.value = '1';
    }
    if (this.active == undefined) {
      this.active = "1";
    }
    this.url = process.env.VUE_APP_API_BASE_URL;
  },
  methods: {
		handClick(index) {
      this.value = `${index}`;
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  text-align: center;
  height: 480px;
  // background-image: url(/img/lianxibg.png);
  // background-size: 100%;
  // line-height: 480px;
	
  .banner-carousel {
    width: 100%;
    height: 480px; /* no */
    position: relative;
    /deep/.el-carousel__container {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
    /deep/.el-carousel__indicators--outside {
      position: absolute;
      left: 0;
      width: 100%;
      .el-carousel__button {
        height: 5px; /* no */
      }
    }
  }
}
.car-header {
  height: 180px;
  background-image: url(/img/pic.png);
	background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 30px 0 0 100px;

  .p1 {
    font-size: 36px;
    font-family: YouSheBiaoTi;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    // text-shadow: 0px 2px 11px rgba(4, 23, 117, 0.88);
    background: linear-gradient(0deg, #a2ffce 0%, #ecffff 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // font-style: oblique;
    margin-bottom: 16px;
  }

  .p2 {
    width: 36px;
    height: 4px;
    background: linear-gradient(90deg, #26faff 0%, #2cff99 100%);
    margin-bottom: 12px;
  }

  .p3, .waste-hot {
    width: 726px;
    font-size: 18px;
    font-family: SourceHanSansCN-Light;
    font-weight: 300;
    color: #ffffff;
    line-height: 26px;
  }
	.waste-hot{
		padding-top: 8px;
	}
	.phone{
		text-indent: 4em;
		font-weight: bold;
		font-size: 24px;
		color: rgb(255, 102, 0);
	}
}
.lastBox-right {
	padding: 2%;
  overflow: hidden;
}
.enterprise {
  margin: 0 auto;
  .p1 {
    span {
      font-size: 14px;
      margin-right: 29px;
    }
  }
}
.recycling-waste-list{
	.recycling-waste-item{
		text-align: center;
		font-size: 20px;
		>img {
			width: 100%;
			height: 300px;
		}
		>span{
			display: inline-block;
			line-height: 50px;
		}
	}
}
/deep/.el-menu-item.is-active {
  background-color: #24a2aa !important;
  color: white !important;
}
/deep/.el-timeline-item__timestamp.is-top {
  position: absolute;
  left: -117px;
  top: -3px;
  color: #333333;
}
/deep/.el-timeline {
  padding-left: 150px;
}
.vjs-custom-skin {
  width: 100%;
  height: 100%;
}
</style>
